import React, { useState } from 'react'
import styled from 'styled-components'
import { Form as AntForm, Button, message, Select, InputNumber, Popconfirm, Input, Typography } from 'antd'
import { useMutation, useQuery, useApolloClient } from '@apollo/client'
import { useParams, useNavigate } from 'react-router-dom'

import { FIND_UNIQUE_SERVICE, UPDATE_ONE_SERVICE, DELETE_ONE_SERVICE, FIND_MANY_CATEGORY, FIND_UNIQUE_CATEGORY } from '../gqls'

const Form = styled(AntForm)`
    max-width: 600px;
`

const Service = () => {
    const [form] = Form.useForm()
    const { id } = useParams()
    const navigate = useNavigate()

    const [categories, setCategories] = useState([])

    const { loading: findUniqueServiceLoading, error: findUniqueServiceError } = useQuery(FIND_UNIQUE_SERVICE, {
        variables: {
            where: {
                id
            }
        },
        onCompleted: async ({ findUniqueService }) => {
            form.setFieldsValue({
                price: findUniqueService.price,
                name: findUniqueService.name,
                category: findUniqueService.categoryId
            })
        }
    })

    useQuery(FIND_MANY_CATEGORY, {
        onCompleted: ({ findManyCategory }) => {
            const categoriesData = findManyCategory.map(object => ({
                value: object.id,
                label: object.subcategory
            }))
            setCategories(categoriesData)
        },
        fetchPolicy: 'network-only'
    })

    const [updateOneServiceMutation, { loading: updateOneServiceLoading }] = useMutation(UPDATE_ONE_SERVICE, {
        onCompleted: () => {
            message.success('Данные обновлены')
        },
        onError: e => {
            message.error('Что то пошло не так, повторите попытку позже')
        }
    })

    const [deleteOneServiceMutation] = useMutation(DELETE_ONE_SERVICE, {
        variables: {
            where: {
                id
            }
        },
        onCompleted: () => {
            message.success('Услуга удалена')
            navigate(-1)
        },
        onError: (err) => {
            console.error(err)
            message.error('Не удалось удалить')
        },
    })

    if (findUniqueServiceLoading || findUniqueServiceError) return null

    const handleSubmit = async (values) => {
        await updateOneServiceMutation({
            variables: {
                where: {
                    id
                },
                data: {
                    name: {
                        set: values.name
                    },
                    category: {
                        connect: {
                            id: values.category
                        }
                    },
                    price: {
                        set: values.price
                    },
                }
            }
        })
    }

    const onDelete = async () => {
        await deleteOneServiceMutation()
    }

    return (
        <>
            <Form form={form} onFinish={handleSubmit} layout='vertical'>
                <Form.Item name='name' label='Название'>
                    <Input placeholder='Название' />
                </Form.Item>
                <Form.Item name='category' label='Выберите категорию' >
                    <Select
                        placeholder='Выберите категорию'
                        options={categories}
                        optionFilterProp='label'
                        showSearch
                    />
                </Form.Item>
                <Form.Item name='price' label='Цена'>
                    <InputNumber placeholder='Цена' />
                </Form.Item>
                <Button loading={updateOneServiceLoading} type='primary' htmlType='submit'>
                    Сохранить
                </Button>
                <Popconfirm title='Удалить?' onConfirm={onDelete}>
                    <Button type='primary' danger style={{ marginLeft: 5 }}>
                        Удалить
                    </Button>
                </Popconfirm>
            </Form>
        </>
    )
}

export default Service
